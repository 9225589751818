import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/default-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Montserrat/Montserrat-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Montserrat/Montserrat-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./Montserrat/Montserrat-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"variable\":\"--font-body\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/default-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./PublicoHeadline/PublicoHeadline-Light-Web.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-LightItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Roman-Web.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Italic-Web.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Medium-Web.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-MediumItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"500\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Bold-Web.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-BoldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Extrabold-Web.woff2\",\"style\":\"normal\",\"weight\":\"800\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-ExtraboldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"800\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-Black-Web.woff2\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"./PublicoHeadline/PublicoHeadline-BlackItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"900\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"publicoHeadlineWeb\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AdSlot"] */ "/app/src/components/AdSlot/AdSlot.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Display"] */ "/app/src/components/Display/Display.component.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/components/Footer/Footer.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/components/Header/Header.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopAd"] */ "/app/src/components/TopAd/TopAd.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyBanner"] */ "/app/src/layouts/Base/components/StickyBanner.component.tsx");
